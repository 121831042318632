import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  redirectUrl?: string;
}

const Error404 = ({ redirectUrl }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl);
    }
  }, []);
  return <p>404 - Nothing to see here ;)</p>;
};

export default Error404;
