import styled from 'styled-components';

export const CornerCountdown = styled.div`
  color: white;
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: #00000000;
  border: none;
  font-size: 15px;
`;
