import React, { useState } from 'react';
import {
  ShareHeader,
  SharePanel,
  ShareButton,
  ShareBody,
  StatLine,
  StatBlock,
  StatName,
  StatValue,
  CopyButton,
} from './share.style';
import { MdContentCopy } from 'react-icons/md';
import { sendEvent } from '../../../utils/event';
import { Guess } from '../../../interfaces/guess.interface';
import { calcAverage, calcNumberComplete, calcStreak } from '../../../utils/calcStats';
import { CookieKey, loadCookie } from '../../../utils/cookies';
import { DailyChallengeHistory } from '../../DailyGame';

interface Props {
  woodleNumber: number;
  guessHistory: Guess[];
}

const digits = ['0️⃣', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣'];

const Stat = ({ name, value }: { name: string; value: string | number }) => {
  return (
    <StatBlock>
      <StatValue>{value}</StatValue>
      <StatName>{name}</StatName>
    </StatBlock>
  );
};

const ShareModal = ({ woodleNumber, guessHistory }: Props) => {
  const [shareButtonText, setShareButtonText] = useState('Share');

  const guessCount = guessHistory.length;

  const challengeHistory = loadCookie<DailyChallengeHistory[]>(CookieKey.MiniDailyChallengeHistory);

  const emojify = (source: number): string => {
    return `${source}`
      .split('')
      .map((digit) => digits[parseInt(digit)])
      .reduce((acc, item) => acc + item, '');
  };

  const guessEntry = (guess: Guess): string => {
    const empty = guess.word.length - (guess.correctPos + guess.incorrectPos);
    return '🟩'.repeat(guess.correctPos) + '🟨'.repeat(guess.incorrectPos) + '🟥'.repeat(empty);
  };

  const guessShareText = (): string => {
    return guessHistory.reduce((output: string, guess: Guess) => {
      if (guess.correctPos === guess.word.length) return output;
      return `${guessEntry(guess)}\n${output}`;
    }, '');
  };

  const shareText = (): string => {
    return `Mini Woodle #${woodleNumber}:\n🟩🟩🟩🟩 in ${emojify(
      guessCount,
    )} guesses!\n${guessShareText()}https://play.woodle.today/mini`;
  };

  const handleClick = async () => {
    sendEvent('share_pressed');
    if (navigator) {
      const text = shareText();
      if (navigator.share && !navigator.userAgent.includes('Windows')) {
        await navigator.share({
          text,
        });
      } else if (navigator.clipboard) {
        setShareButtonText('Copied to clipboard!');
        await navigator.clipboard.writeText(text);
      }
    }
  };

  const handleCopyClick = async () => {
    sendEvent('copy_pressed');
    if (navigator) {
      const text = shareText();
      if (navigator.clipboard) {
        setShareButtonText('Copied to clipboard!');
        await navigator.clipboard.writeText(text);
        setTimeout(() => {
          setShareButtonText('Share');
        }, 2500);
      }
    }
  };

  const streakStats = calcStreak(challengeHistory);
  return (
    <>
      <SharePanel
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ShareHeader>Congratulations! 🎉</ShareHeader>
        <ShareBody>
          You successfully completed <b>Mini Woodle #{woodleNumber}</b> in {guessCount} guesses!
        </ShareBody>
        <ShareBody>
          <StatLine>
            <Stat name={'Total complete'} value={calcNumberComplete(challengeHistory)} />
            <Stat name={'Average guesses'} value={calcAverage(challengeHistory).toFixed(1)} />
            <Stat name={'Current streak'} value={streakStats.currentStreak} />
            <Stat name={'Max streak'} value={streakStats.maxStreak} />
          </StatLine>
        </ShareBody>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <ShareButton
            onClick={async (e) => {
              e.stopPropagation();
              e.persist();
              await handleClick();
            }}
          >
            {shareButtonText}
          </ShareButton>
          <CopyButton
            onClick={async (e) => {
              e.stopPropagation();
              e.persist();
              await handleCopyClick();
            }}
          >
            <MdContentCopy />
          </CopyButton>
        </div>
      </SharePanel>
      {/*<Adsense client="ca-pub-7256299683842240" slot="7493424826" responsive="true" format="auto" />*/}
    </>
  );
};

export default ShareModal;
