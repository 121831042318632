import moment from 'moment';
import React from 'react';
import { CornerCountdown } from './style';
import Countdown from 'react-countdown';
import { getWoodleNumber } from '../../utils/word-picker';

export const nextWoodleDate = () =>
  moment().set({ hour: 0, minute: 0, second: 0, millisecond: 1 }).add(1, 'day');

export type CornerPromptProps = {
  overrideContents?: React.ReactNode;
  hidden?: boolean;
  woodleNumber?: number;
};

const CornerPrompt = ({ overrideContents, hidden = false, woodleNumber }: CornerPromptProps) => {
  if (hidden) {
    return <></>;
  }

  if (overrideContents) {
    return <CornerCountdown>{overrideContents}</CornerCountdown>;
  }
  const nextWoodleNumber = (woodleNumber ? woodleNumber : getWoodleNumber()) + 1;

  return (
    <CornerCountdown>
      <Countdown
        daysInHours
        date={nextWoodleDate().toDate()}
        renderer={(props) => {
          return (
            <span>
              {/* eslint-disable-next-line react/prop-types */}
              Woodle #{nextWoodleNumber}: {props.hours.toString().padStart(2, '0')}:
              {/* eslint-disable-next-line react/prop-types */}
              {props.minutes.toString().padStart(2, '0')}:
              {/* eslint-disable-next-line react/prop-types */}
              {props.seconds.toString().padStart(2, '0')}
            </span>
          );
        }}
      />
    </CornerCountdown>
  );
};

export default CornerPrompt;
