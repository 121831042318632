import styled from 'styled-components';

export const WordInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SingleInputContainer = styled.div`
  margin-right: 5px;

  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  height: 5rem;
  width: 5rem;
  font-size: 2.5rem;
  background-color: #fff;
  border-radius: 1rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 100%;
`;
