import React, { ReactNode, useEffect, useState } from 'react';
import { Color, Guess } from '../../interfaces/guess.interface';
import GuessHistory from '../History';
import { CornerButton, GameBoard, GameInput } from './game.style';
import { CookieKey, loadCookie, storeCookie } from '../../utils/cookies';
import Keyboard from '../Keyboard';
import WordInput from '../WordInput';
import { MdHelpOutline, MdShare } from 'react-icons/md';
import Help from '../Help';
import { sendEvent } from '../../utils/event';
import ModalContainer from '../Modal';
import { isValidGuess, NumberLetters } from '../../utils/word-picker';

export interface ResumeGame {
  answer: string;
  guessHistory: Guess[];
  guess?: string;
}

export type GameProps = {
  answer: string;
  onIncorrectGuess?: (guessHistory: Guess[]) => void;
  onCorrectAnswer: (guessHistory: Guess[]) => void;
  resumeGame?: ResumeGame;
  header: ReactNode;
  shareDialog?: ReactNode;
  numLetters: NumberLetters;
};

const Game = ({
  answer,
  onCorrectAnswer,
  resumeGame,
  header,
  shareDialog,
  onIncorrectGuess,
  numLetters,
}: GameProps) => {
  const [guess, setGuess] = useState<string>('');
  const [guessHistory, setGuessHistory] = useState<Guess[]>([]);
  const [inputError, setInputError] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState<boolean>(true);
  const [showShare, setShowShare] = useState(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);

  useEffect(() => {
    if (!resumeGame) {
      return;
    }
    setupGame(resumeGame.answer, resumeGame.guessHistory, resumeGame.guess);
  }, [resumeGame]);

  useEffect(() => {
    if (!answer || (resumeGame?.answer && resumeGame.answer === answer)) {
      return;
    }
    newGame(answer);
  }, [answer]);

  useEffect(() => {
    if (!loadCookie<boolean>(CookieKey.RepeatVisit)) {
      setShowHelp(true);
      storeCookie<boolean>(CookieKey.RepeatVisit, true);
    }
  }, []);

  const newGame = (answer: string) => {
    setupGame(answer, []);
  };

  const setupGame = (answer: string, guessHistory: Guess[], guess = '') => {
    guessHistory.forEach((guess) => {
      if (!guess.letterColors) {
        guess.letterColors = guess.word.split('').map(() => Color.White);
      }
    });

    setGuessHistory(guessHistory);
    setGuess(guess);
    setIsCorrect(answer === guess);
  };

  const addGuess = (guess: Guess) => {
    guess.letterColors = guess.word.split('').map(() => Color.White);
    const history = [...guessHistory, guess];
    setGuessHistory(history);
    return history;
  };

  const compareAnswer = () => {
    if (!isValidGuess(numLetters)(guess)) {
      setInputError(true);
      return;
    }
    if (guessHistory.length === 0) {
      sendEvent('game_started', { guess });
    }
    sendEvent(`word_guessed`, { guess });
    setInputError(false);
    const incorrect: string[] = [];
    const unmatched: string[] = [];

    Array.from(guess).forEach((character, index) => {
      const ansChar = answer[index];
      if (character !== ansChar) {
        incorrect.push(character);
        unmatched.push(ansChar);
      }
    });

    const correctPos = answer.length - unmatched.length;

    incorrect.forEach((character) => {
      const index = unmatched.indexOf(character);
      if (index !== -1) {
        unmatched.splice(index, 1);
      }
    });

    const incorrectPos = answer.length - correctPos - unmatched.length;

    setGuess('');
    const history = addGuess({ word: guess, correctPos, incorrectPos });

    onIncorrectGuess && onIncorrectGuess(history);
  };

  const checkWord = () => {
    if (guess === answer) {
      setIsCorrect(true);
      const history = addGuess({ word: guess, correctPos: answer.length, incorrectPos: 0 });
      onCorrectAnswer(history);
      setShowShare(true);
      return;
    }

    compareAnswer();
  };

  const submit = () => {
    if (guess.length === answer.length && !isCorrect) {
      checkWord();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showHelp, showShare]);

  const inputColor = isCorrect ? '#aaffaa' : inputError ? '#ffbbbb' : '#ffffff';

  const showCornerButton = !showShare && !showHelp;
  const cornerButtonIcon = isCorrect ? <MdShare size="2.5rem" /> : <MdHelpOutline size="2.5rem" />;
  const cornerButtonClick = isCorrect ? () => setShowShare(true) : () => setShowHelp(true);

  return (
    <>
      <GameBoard>
        {showCornerButton && (
          <CornerButton onClick={cornerButtonClick}>{cornerButtonIcon}</CornerButton>
        )}
        {header}
        <GameInput
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              submit();
            }
          }}
        >
          <WordInput
            word={guess}
            setWord={setGuess}
            numInputs={answer.length}
            setShowKeyboard={setShowKeyboard}
            backgroundColor={inputColor}
          />
        </GameInput>

        {!!guessHistory.length && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GuessHistory guessHistory={guessHistory} setGuessHistory={setGuessHistory} />
          </div>
        )}
      </GameBoard>
      <Keyboard
        expanded={showKeyboard}
        onToggleExpanded={setShowKeyboard}
        pressLetter={(character: string) => {
          if (guess.length < answer.length) {
            setGuess(guess + character.toLowerCase());
          }
        }}
        onBackspacePress={() => {
          setInputError(false);
          if (guess.length) {
            setGuess(guess.substring(0, guess.length - 1));
          }
        }}
        onEnterPress={submit}
        guessHistory={guessHistory}
        disabled={isCorrect}
      />
      {showHelp && <Help onDismiss={() => setShowHelp(false)} />}
      {showShare && !!shareDialog && isCorrect && (
        <ModalContainer onDismiss={() => setShowShare(false)}>{shareDialog}</ModalContainer>
      )}
    </>
  );
};

export default Game;
