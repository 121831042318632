export enum CookieKey {
  DailyChallengeHistory = 'dailyChallengeHistory',
  MiniDailyChallengeHistory = 'miniDailyChallengeHistory',
  GuessHistory = 'guessHistory',
  MiniGuessHistory = 'miniGuessHistory',
  CurrentDay = 'currentDay',
  MiniCurrentDay = 'miniCurrentDay',
  ID = 'id',
  RepeatVisit = 'repeatVisit',
  UnlimitedSeed = 'unlimitedSeed',
  LastRefresh = 'lastRefresh',
}

export const loadCookie = <T>(key: CookieKey, fallback?: T): T | undefined => {
  const contents = localStorage.getItem(key);
  if (!contents) {
    return fallback;
  }

  return JSON.parse(contents);
};

export const storeCookie = <T>(key: CookieKey, contents: T) => {
  const s = JSON.stringify(contents);
  localStorage.setItem(key, s);
};
