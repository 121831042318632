import styled from 'styled-components';

export const GuessHistoryItemContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: calc(100%);
`;

export const Index = styled.div`
  font-size: 1.2rem;
  margin-right: 1rem;
  padding: auto;
  justify-text: center;
  width: 1.5rem;
  min-width: 1.5rem;
`;
