import SingleInput from './SingleInput';
import React, { ReactNode } from 'react';
import { WordInputContainer } from './word-input.style';

interface Props {
  word: string;
  setWord: (word: string) => void;
  numInputs: number;
  setShowKeyboard: (show: boolean) => void;
  backgroundColor: string;
}

const WordInput = ({ numInputs, word, setShowKeyboard, backgroundColor }: Props) => {
  const inputs: ReactNode[] = [];
  for (let i = 0; i < numInputs; i++) {
    inputs.push(<SingleInput letter={word.charAt(i)} backgroundColor={backgroundColor} key={i} />);
  }
  return (
    <WordInputContainer onClick={() => setShowKeyboard(true)}>
      {inputs.map((input) => {
        return input;
      })}
    </WordInputContainer>
  );
};

export default WordInput;
