import styled from 'styled-components';

export const KeyboardContainerExpanded = styled.div`
  bottom: -1px;
  position: fixed;
  height: 27vh;
  min-height: 25vh;
  width: 100vw;
  background-color: #1a1a1af0;
  padding-bottom: 1.5rem;
`;

export const KeyboardContainerCollapsed = styled.div`
  bottom: -1px;
  position: fixed;
  width: 100vw;
  background-color: #1a1a1af0;
`;

export const ToggleButton = styled.button`
  color: white;
  position: relative;
  top: 2px;
  right: calc(-50% + 30px);
  background-color: #00000000;
  border: none;
  font-size: 15px;
`;

export const RowContainer = styled.div`
  display: flex;
  height: 29%;
  align-items: stretch;
  margin-left: 2vw;
  margin-right: 2vw;
  justify-content: center;
`;

export const Key = styled.div`
  cursor: pointer;
  flex: 1;
  fontsize: 1em;
  background-color: white;
  border-radius: 10px;
  color: black;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  align-items: center;
  justify-content: center;
  line-height: 5.5vh;
  height: 75%;
  width: 3rem;
  max-width: 3rem;
  user-select: none;
`;

export const SpecialKey = styled.div`
  cursor: pointer;
  display: flex;
  background-color: white;
  border-radius: 10px;
  color: black;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  align-items: center;
  justify-content: center;
  height: 75%;
  width: 4rem;
  max-width: 4rem;
  user-select: none;
  font-size: 13px;
`;
