import styled from 'styled-components';

export const HelpPanel = styled.div`
  border-radius: 10px;
  background-color: #282c34;
  position: absolute;
  min-height: 250px;
  min-width: 45%;
  max-width: min(90%, 750px);
  padding: 2rem;
  margin-top: 3em;
  font-size: 1.25rem;
`;

export const ModalFade = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 100%;
  min-width: 100%;
  z-index: 99;
  background-color: #00000099;
`;

export const Correct = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #339933;
`;

export const Incorrect = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #dcbc3a;
`;

export const NotInWord = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: red;
`;

export const HelpHeader = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
`;

export const CloseButton = styled.button`
  color: white;
  position: absolute;
  right: 0;
  background-color: #00000000;
  border: none;
  font-size: 15px;
  cursor: pointer;
`;
