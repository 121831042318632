import { answer4Letters, answers5Letters, valid4Words, valid5Words } from '../words';
import moment from 'moment';

function mulberry32(seed: number) {
  return function () {
    let t = (seed += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export enum NumberLetters {
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}

const Answers: Record<number, string[]> = {
  [NumberLetters.FOUR]: answer4Letters,
  [NumberLetters.FIVE]: answers5Letters,
};

const ValidWords: Record<number, string[]> = {
  [NumberLetters.FOUR]: valid4Words,
  [NumberLetters.FIVE]: valid5Words,
};

export const isValidGuess = (numLetters: NumberLetters) => {
  const answers = Answers[numLetters];
  const validWords = ValidWords[numLetters];
  return (guess: string): boolean => {
    return validWords.includes(guess) || answers.includes(guess);
  };
};

export const getRandomWord = (numLetters: NumberLetters, seed?: number) => {
  const answers = Answers[numLetters];
  if (!seed) {
    return answers[Math.floor(Math.random() * answers.length)].toLowerCase();
  }
  return answers[Math.floor(mulberry32(seed)() * answers.length)].toLowerCase();
};

export const getDay = () => {
  const firstDate = moment('2022-02-12 00-00-01', 'YYYY-MM-DD hh-mm-ss');
  const today = moment();
  return today.diff(firstDate, 'days') + 19035;
};

export const getWoodleNumber = () => {
  return getDay() - 19035;
};

export const getMiniWoodleNumber = () => {
  return getWoodleNumber() - 267;
};

export const getTodaysWord = (numLetters: NumberLetters) => {
  const answers = Answers[numLetters];
  return answers[getWoodleNumber() - 1].toLowerCase();
};

export const getWoodleList = (upTo: number): string[] => {
  const list = [];
  for (let i = 1; i < upTo + 1; i++) {
    list.push(
      answers5Letters[Math.floor(mulberry32(i + 19035)() * answers5Letters.length)].toLowerCase(),
    );
  }
  return list;
};
