import React from 'react';
import { ShareHeader, SharePanel, ShareBody, PlayButton } from './share.style';
import { Guess } from '../../../interfaces/guess.interface';

interface Props {
  guessHistory: Guess[];
  onPlayAgain: () => void;
}

const UnlimitedShareModal = ({ guessHistory, onPlayAgain }: Props) => {
  const guessCount = guessHistory.length;

  return (
    <>
      <SharePanel
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ShareHeader>Congratulations! 🎉</ShareHeader>
        <ShareBody>You successfully completed this woodle in {guessCount} guesses!</ShareBody>
        <br />
        <PlayButton onClick={onPlayAgain}>Play Again</PlayButton>
      </SharePanel>
    </>
  );
};

export default UnlimitedShareModal;
