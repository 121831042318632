import React from 'react';
import { Guess } from '../../interfaces/guess.interface';
import GuessHistoryItem from './Item';
import { HistoryListContainer, ResetButton } from './history.style';
import { Adsense } from '@ctrl/react-adsense';
interface Props {
  guessHistory: Guess[];
  setGuessHistory: (guessHistory: Guess[]) => void;
}

const GuessHistory = ({ guessHistory, setGuessHistory }: Props) => {
  const resetMarkings = () => {
    setGuessHistory(
      guessHistory.map((guess) => {
        guess.letterColors = undefined;
        return guess;
      }),
    );
  };

  if (!guessHistory) {
    return <></>;
  }
  return (
    <div>
      <HistoryListContainer>
        {!guessHistory?.length && (
          <Adsense client="ca-pub-7256299683842240" slot="5115545637" responsive="true" />
        )}
        <ResetButton onClick={resetMarkings}>RESET</ResetButton>
        {guessHistory.map((guess, index) => {
          return (
            <GuessHistoryItem
              guess={guess}
              key={index}
              index={index + 1}
              updateGuess={(guess) => {
                guessHistory[index] = guess;
                setGuessHistory([...guessHistory]);
              }}
            />
          );
        })}
      </HistoryListContainer>
    </div>
  );
};
export default GuessHistory;
