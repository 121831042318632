export enum Color {
  White = '#ffffff',
  Yellow = '#dcbc3a',
  Green = '#339933',
  Red = '#aa3333',
}

const colors = [Color.White, Color.Yellow, Color.Green, Color.Red];

export const nextColor = (color: Color): Color => {
  const colorIndex = colors.indexOf(color);
  const nextIndex = colorIndex >= 0 ? (colorIndex + 1) % colors.length : 0;

  return colors[nextIndex];
};

export interface Guess {
  word: string;
  correctPos: number;
  incorrectPos: number;
  letterColors?: Color[];
}
