import Plausible from 'plausible-tracker';
const { trackEvent } = Plausible();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendEvent = (eventName: string, data?: any) => {
  try {
    trackEvent(eventName, { props: data } || {});
  } catch (e) {
    // ignore; no events for you
  }
};
