import { CookieKey, loadCookie } from './cookies';
import { DailyChallengeHistory } from '../components/DailyGame';

export const calcStreak = (overrideChallengeHistory?: DailyChallengeHistory[]) => {
  const challengeHistory =
    overrideChallengeHistory ||
    loadCookie<DailyChallengeHistory[]>(CookieKey.DailyChallengeHistory);
  let prevWoodle = 0;
  let maxStreak = 1;
  let currentStreak = 0;
  let streak = 0;

  if (challengeHistory?.length === 1) {
    return { maxStreak: 1, currentStreak: 1 };
  }

  challengeHistory?.forEach((entry) => {
    const streakBroken = prevWoodle - 1 !== entry.day;
    prevWoodle = entry.day;

    if (streakBroken) {
      if (!currentStreak) {
        currentStreak = streak;
      }
      streak = 1;
      return;
    }

    streak = streak + 1;
    maxStreak = Math.max(maxStreak, streak);
  });

  if (!currentStreak) {
    currentStreak = streak;
  }

  return { maxStreak, currentStreak };
};

export const calcAverage = (overrideChallengeHistory?: DailyChallengeHistory[]) => {
  const challengeHistory =
    overrideChallengeHistory ||
    loadCookie<DailyChallengeHistory[]>(CookieKey.DailyChallengeHistory);
  const totalGuesses =
    challengeHistory?.reduce((total: number, entry) => {
      return total + entry.noGuesses;
    }, 0) || 0;
  return totalGuesses / calcNumberComplete(challengeHistory);
};

export const calcNumberComplete = (overrideChallengeHistory?: DailyChallengeHistory[]) => {
  const challengeHistory =
    overrideChallengeHistory ||
    loadCookie<DailyChallengeHistory[]>(CookieKey.DailyChallengeHistory);
  return challengeHistory?.length || 0;
};
