import React, { useEffect } from 'react';
import { CookieKey, loadCookie, storeCookie } from './utils/cookies';
import { v4 } from 'uuid';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Plausible from 'plausible-tracker';
import DailyGame from './components/DailyGame';
import UnlimitedGame from './components/UnlimitedGame';
import { NumberLetters } from './utils/word-picker';
import MiniDailyGame from './components/MiniDailyGame';
import Error404 from './components/404';
import SideDrawer from './components/Drawer';
import 'antd/dist/antd.dark.css';
import './App.css';

Plausible({
  domain: 'play.woodle.today',
});

const App = () => {
  useEffect(() => {
    if (!loadCookie(CookieKey.ID)) {
      storeCookie(CookieKey.ID, v4());
    }
    const lastRefresh = loadCookie<number>(CookieKey.LastRefresh);
    if (!lastRefresh) {
      storeCookie(CookieKey.LastRefresh, Date.now());
    } else if (Date.now() - lastRefresh > 1000 * 60 * 60 * 24 * 7) {
      storeCookie(CookieKey.LastRefresh, Date.now());
      window.location.reload();
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">WOODLE</header>
      <div className="App-body">
        <BrowserRouter>
          <SideDrawer />
          <Routes>
            <Route path={'/'} element={<DailyGame numLetters={NumberLetters.FIVE} />} />
            <Route path={'/mini'} element={<MiniDailyGame numLetters={NumberLetters.FOUR} />} />
            <Route path={'/unlimited'}>
              <Route
                path={'/unlimited/mini'}
                element={<UnlimitedGame numLetters={NumberLetters.FOUR} />}
              />
              <Route
                path={'/unlimited'}
                element={<UnlimitedGame numLetters={NumberLetters.FIVE} />}
              />
            </Route>
            <Route path={'/*'} element={<Error404 redirectUrl={'/'} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
