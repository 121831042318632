import styled from 'styled-components';

export const DrawerButton = styled.button`
  position: fixed;
  left: 0px;
  top: 50vh;
  background-color: #00000000;
  border: none;
  font-size: 15px;
  cursor: pointer;
  padding: 0px;
`;
