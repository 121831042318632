import styled from 'styled-components';

export const HistoryListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 280px;
  flex-direction: column-reverse;
  padding-bottom: 28.5vh;
`;

export const ResetButton = styled.div`
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1.5rem;
`;
