import { Guess } from '../../interfaces/guess.interface';
import React, { useEffect, useState } from 'react';
import { getDay, getRandomWord, NumberLetters } from '../../utils/word-picker';
import { HeaderContainer, PlayAgainButton } from '../Game/game.style';
import Game from '../Game';
import { sendEvent } from '../../utils/event';
import UnlimitedShareModal from './Share';
import CornerPrompt from '../CornerPrompt';
import { Link } from 'react-router-dom';
import { CookieKey, loadCookie } from '../../utils/cookies';
import { ReloadOutlined } from '@ant-design/icons';

interface Props {
  numLetters: NumberLetters;
}

const UnlimitedGame = ({ numLetters }: Props) => {
  const [answer, setAnswer] = useState<string>('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [guessHistory, setGuessHistory] = useState<Guess[]>([]);

  useEffect(() => {
    if (!answer) {
      setupRandomChallenge();
    }
  }, []);

  useEffect(() => {
    setupRandomChallenge();
  }, [numLetters]);

  const setupRandomChallenge = (seed?: number) => {
    const newAnswer = getRandomWord(numLetters, seed);
    setIsCorrect(false);
    setAnswer(newAnswer);
  };

  const onCorrectAnswer = (guessHistory: Guess[]) => {
    setIsCorrect(true);
    setGuessHistory(guessHistory);
    sendEvent(`completed_game_unlimited`, { noGuesses: guessHistory.length, answer });
  };

  const dailySolved = loadCookie<number>(CookieKey.CurrentDay) === getDay();

  return (
    <>
      <Game
        answer={answer}
        numLetters={numLetters}
        header={
          <>
            <HeaderContainer>
              Unlimited
              {isCorrect && (
                <PlayAgainButton onClick={() => setupRandomChallenge()} icon={<ReloadOutlined />}>
                  Play again
                </PlayAgainButton>
              )}
            </HeaderContainer>
          </>
        }
        onCorrectAnswer={onCorrectAnswer}
        shareDialog={
          <UnlimitedShareModal
            guessHistory={guessHistory}
            onPlayAgain={() => setupRandomChallenge()}
          />
        }
      />
      <CornerPrompt
        overrideContents={
          !dailySolved && (
            <Link style={{ color: 'white' }} to={numLetters === NumberLetters.FIVE ? '/' : '/mini'}>
              Play the daily Woodle!
            </Link>
          )
        }
      />
    </>
  );
};

export default UnlimitedGame;
