import styled from 'styled-components';

export const SharePanel = styled.div`
  border-radius: 10px;
  background-color: #282c34;
  position: absolute;
  min-height: 200px;
  min-width: 45%;
  max-width: min(80%, 1000px);
  padding: 1rem;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TwitterPanel = styled.div`
  border-radius: 10px;
  background-color: #282c34;
  position: absolute;
  bottom: 20px;
  min-height: 200px;
  min-width: 45%;
  max-width: min(70%, 1000px);
  padding: 2rem;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModalFade = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 100%;
  min-width: 100%;
  z-index: 99;
  background-color: #00000099;
`;

export const Correct = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #339933;
`;

export const Incorrect = styled.span`
  margin-left: 0.2em;
  margin-right: 0.2em;
  color: #dcbc3a;
`;

export const ShareHeader = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
export const ShareBody = styled.div`
  font-size: 1em;
  margin-bottom: 0.5rem;
`;
export const TwitterBody = styled.div`
  font-size: 1em;
  margin-bottom: 0.5rem;
`;

export const CloseButton = styled.button`
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #00000000;
  border: none;
  font-size: 15px;
  cursor: pointer;
`;

export const ShareButton = styled.button`
  color: black;
  flex-grow: 1;
  border-radius: 1rem;
  height: 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fff;
  z-index: 100;
  margin-right: 0.5rem;
`;

export const CopyButton = styled.button`
  border-radius: 1rem;
  width: 4rem;
  height: 4rem;
  font-size: 20px;
  font-weight: bold;
  background-color: #fff;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

export const StatLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StatBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const StatName = styled.div`
  font-size: 0.7em;
`;

export const StatValue = styled.div`
  font-weight: bold;
  font-size: 1.25em;
`;
