import styled from 'styled-components';

export const SharePanel = styled.div`
  border-radius: 10px;
  background-color: #282c34;
  position: absolute;
  min-height: 150px;
  min-width: 45%;
  max-width: min(80%, 1000px);
  padding: 1rem;
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShareHeader = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;
export const ShareBody = styled.div`
  font-size: 1em;
  margin-bottom: 0.5rem;
`;

export const PlayButton = styled.button`
  color: black;
  flex-grow: 1;
  border-radius: 1rem;
  height: 4rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fff;
  z-index: 100;
  margin-right: 0.5rem;
`;
