import React from 'react';
import { Color, Guess, nextColor } from '../../../interfaces/guess.interface';
import { GuessHistoryItemContainer, Index } from './item.style';

interface Props {
  guess: Guess;
  updateGuess: (guess: Guess) => void;
  index: number;
}

const Letter = ({
  letter,
  color,
  onClick,
}: {
  letter: string;
  color: Color;
  onClick: () => void;
}) => {
  return (
    <div
      style={{
        backgroundColor: color,
        marginRight: '5px',
        minWidth: '40px',
        height: '40px',
        width: '40px',
        fontSize: '0.75em',
        borderRadius: '10px',
        caretColor: 'transparent',
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <span>{letter}</span>
    </div>
  );
};

const GuessHistoryItem = ({ guess, updateGuess, index }: Props) => {
  return (
    <GuessHistoryItemContainer>
      <Index>{index}</Index>
      {guess.word.split('').map((letter, index) => {
        let color = Color.White;
        if (guess.letterColors && guess.letterColors.length > index) {
          color = guess.letterColors[index];
        }
        return (
          <Letter
            letter={letter}
            key={index}
            color={color}
            onClick={() => {
              if (!guess.letterColors) {
                guess.letterColors = guess.word.split('').map(() => Color.White);
              }
              guess.letterColors[index] = nextColor(color);
              updateGuess({
                ...guess,
              });
            }}
          />
        );
      })}
      <div style={{ width: '1em', flexDirection: 'column', display: 'flex', paddingRight: '1rem' }}>
        <span
          style={{
            marginLeft: '10px',
            color: '#339933',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {guess.correctPos}
        </span>
        <span
          style={{
            marginLeft: '10px',
            color: '#dcbc3a',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          {guess.incorrectPos}
        </span>
      </div>
    </GuessHistoryItemContainer>
  );
};
export default GuessHistoryItem;
