import { Button, Divider, Drawer } from 'antd';
import React, { useState } from 'react';
import { DrawerButton } from './drawer.style';
import { MdChevronRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const SideDrawer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const buttons = [
    { text: 'Play Daily Woodle', link: '/' },
    { text: 'Play Daily Mini', link: '/mini' },
    { text: 'Play Unlimited', link: '/unlimited' },
    { text: 'Play Mini Unlimited', link: '/unlimited/mini' },
  ];

  return (
    <>
      {!open && (
        <DrawerButton onClick={() => showDrawer()}>
          <MdChevronRight size="30px" />
        </DrawerButton>
      )}
      <Drawer
        bodyStyle={{ overflowX: 'hidden' }}
        headerStyle={{ border: 'none', paddingBottom: '0px' }}
        placement="left"
        onClose={onClose}
        open={open}
        width={'min(250px, 70vw)'}
      >
        {buttons.flatMap((b, i) => {
          const elms = [
            <Button
              style={{ width: '100%', textAlign: 'left' }}
              type={'text'}
              size={'large'}
              onClick={() => {
                navigate(b.link);
                setOpen(false);
              }}
              key={`button-${i}`}
            >
              {b.text}
            </Button>,
          ];
          if (i < buttons.length - 1) {
            elms.push(<Divider key={`divider-${i}`} style={{ margin: '5px' }} />);
          }
          return elms;
        })}
      </Drawer>
    </>
  );
};
export default SideDrawer;
