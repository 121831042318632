import React from 'react';
import { SingleInputContainer } from '../word-input.style';
interface Props {
  letter: string;
  backgroundColor: string;
}
const SingleInput = ({ letter, backgroundColor }: Props) => {
  return (
    <SingleInputContainer style={{ backgroundColor }}>
      <span>{letter}</span>
    </SingleInputContainer>
  );
};
export default SingleInput;
