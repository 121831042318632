import React, { useEffect } from 'react';
import {
  KeyboardContainerExpanded,
  KeyboardContainerCollapsed,
  ToggleButton,
  RowContainer,
  Key,
  SpecialKey,
} from './keyboard.style';
import { MdBackspace, MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Color, Guess } from '../../interfaces/guess.interface';

interface Props {
  expanded: boolean;
  onToggleExpanded: (val: boolean) => void;
  pressLetter: (letter: string) => void;
  onBackspacePress: () => void;
  onEnterPress: () => void;
  guessHistory: Guess[];
  disabled: boolean;
}

const Keyboard = ({
  expanded,
  onToggleExpanded,
  pressLetter,
  onBackspacePress,
  onEnterPress,
  guessHistory,
  disabled,
}: Props) => {
  const keyListener = (event: KeyboardEvent) => {
    if (disabled) {
      return;
    }

    if (event.key === 'Enter') {
      onEnterPress();
    } else if (event.key === 'Backspace') {
      onBackspacePress();
    } else if (event.key.match(/^[a-zA-Z]$/)) {
      pressLetter(event.key.toUpperCase());
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyListener, true);
    return () => window.removeEventListener('keyup', keyListener, true);
  });

  return disabled ? (
    <></>
  ) : expanded ? (
    <ExpandedKeyboard
      collapse={() => onToggleExpanded(false)}
      pressLetter={pressLetter}
      onBackspacePress={onBackspacePress}
      onEnterPress={onEnterPress}
      guessHistory={guessHistory}
    />
  ) : (
    <CollapsedKeyboard expand={() => onToggleExpanded(true)} />
  );
};

interface ExpandedProps {
  collapse: () => void;
  pressLetter: (letter: string) => void;
  onBackspacePress: () => void;
  onEnterPress: () => void;
  guessHistory: Guess[];
}

const ExpandedKeyboard = ({
  collapse,
  pressLetter,
  onBackspacePress,
  onEnterPress,
  guessHistory,
}: ExpandedProps) => {
  const lettersByColor = {
    [Color.Red]: new Set<string>(),
    [Color.Green]: new Set<string>(),
    [Color.Yellow]: new Set<string>(),
    [Color.White]: new Set<string>(),
  };
  guessHistory.forEach((guess) => {
    guess.word.split('').forEach((c, index) => {
      if (guess.letterColors) {
        lettersByColor[guess.letterColors[index]].add(c.toLowerCase());
      }
    });
  });

  return (
    <KeyboardContainerExpanded>
      <ToggleButton onClick={collapse}>
        <MdExpandMore size={'30px'} />
      </ToggleButton>
      <Row
        buttons={'QWERTYUIOP'.split('')}
        pressLetter={pressLetter}
        lettersByColor={lettersByColor}
      />
      <Row
        buttons={'ASDFGHJKL'.split('')}
        pressLetter={pressLetter}
        lettersByColor={lettersByColor}
      />
      <Row
        buttons={'ZXCVBNM'.split('')}
        pressLetter={pressLetter}
        onEnterPress={onEnterPress}
        onBackPress={onBackspacePress}
        lettersByColor={lettersByColor}
      />
    </KeyboardContainerExpanded>
  );
};

interface CollapsedProps {
  expand: () => void;
}

const CollapsedKeyboard = ({ expand }: CollapsedProps) => {
  return (
    <KeyboardContainerCollapsed onClick={expand}>
      <ToggleButton>
        <MdExpandLess size={'30px'} />
      </ToggleButton>
    </KeyboardContainerCollapsed>
  );
};

interface RowProps {
  buttons: string[];
  pressLetter: (letter: string) => void;
  onBackPress?: () => void;
  onEnterPress?: () => void;
  lettersByColor: { [key in Color]: Set<string> };
}

const Row = ({ buttons, pressLetter, onBackPress, onEnterPress, lettersByColor }: RowProps) => {
  return (
    <RowContainer>
      {onEnterPress && (
        <SpecialKey onClick={onEnterPress}>
          <span>ENTER</span>
        </SpecialKey>
      )}
      {buttons.map((character) => {
        const backgroundColor = determineColor(lettersByColor, character);
        return (
          <Key key={character} style={{ backgroundColor }} onClick={() => pressLetter(character)}>
            <span>{character}</span>
          </Key>
        );
      })}

      {onBackPress && (
        <SpecialKey onClick={onBackPress}>
          <MdBackspace size="1.5rem" />
        </SpecialKey>
      )}
    </RowContainer>
  );
};

const determineColor = (lettersByColor: { [key in Color]: Set<string> }, char: string): string => {
  for (const color of [Color.Green, Color.Yellow, Color.Red, Color.White]) {
    if (lettersByColor[color] && lettersByColor[color].has(char.toLowerCase())) {
      return color == Color.White ? '#aaaab0' : color;
    }
  }

  return Color.White;
};

export default Keyboard;
