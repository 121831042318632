import styled from 'styled-components';
import { Button } from 'antd';

export const GameBoard = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

export const GameInput = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: sticky;
  top: -1px;
  background-color: #282c34;
`;

export const SubmitButton = styled.button`
  margin-left: 15px;
  height: calc(3.5rem + 6px);
  width: calc(3.5rem + 8px);
  font-size: 0.7rem;
  background-color: #ffffff;
  border-radius: 1rem;
  word-wrap: normal;
`;

export const CornerButton = styled.button`
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #00000000;
  border: none;
  font-size: 15px;
  cursor: pointer;
`;

export const NumberOfGuesses = styled.div`
  font-size: 15px;
  align-self: flex-end;
  float: right;
`;

export const HeaderContainer = styled.div`
  justify-self: center;
  align-self: center;
  flex-direction: column;
  display: flex;
`;

export const PlayAgainButton = styled(Button)`
  color: black;
  width: 11rem;
  margin-top: 1rem;
  flex-grow: 1;
  border-radius: 1rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  background-color: #fff;
  padding: 0 1rem 0 1rem;

  &:active,
  &:focus,
  &:hover {
    color: black;
    background-color: #f0f0f0;
  }
`;
