import React, { ReactNode } from 'react';
import { CloseButton, ModalFade } from './modal.style';
import { MdClose } from 'react-icons/md';

interface Props {
  onDismiss: () => void;
  children: ReactNode | ReactNode[];
}

const ModalContainer = ({ onDismiss, children }: Props) => {
  return (
    <ModalFade onClick={onDismiss}>
      <CloseButton>
        <MdClose size="2.5em" />
      </CloseButton>
      {children}
    </ModalFade>
  );
};

export default ModalContainer;
