import { Guess } from '../../interfaces/guess.interface';
import React, { useEffect, useState } from 'react';
import { getDay, getTodaysWord, getWoodleNumber, NumberLetters } from '../../utils/word-picker';
import { CookieKey, loadCookie, storeCookie } from '../../utils/cookies';
import moment from 'moment';
import { HeaderContainer, PlayAgainButton } from '../Game/game.style';
import ShareModal from './Share';
import Game, { ResumeGame } from '../Game';
import { sendEvent } from '../../utils/event';
import { useNavigate } from 'react-router-dom';
import CornerPrompt, { nextWoodleDate } from '../CornerPrompt';
import { DailyChallengeHistory } from '../DailyGame';

interface Props {
  numLetters: NumberLetters;
}

const MiniDailyGame = ({ numLetters }: Props) => {
  const [answer, setAnswer] = useState<string>('');
  const [day, setDay] = useState(0);
  const [isCorrect, setIsCorrect] = useState(false);
  const [resumeGame, setResumeGame] = useState<ResumeGame | undefined>(undefined);
  const [guessHistory, setGuessHistory] = useState<Guess[]>([]);
  const navigate = useNavigate();
  const woodleNumber = getWoodleNumber() - 267;

  useEffect(() => {
    setDay(getDay());
    checkDailyWord();
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (getDay() !== loadCookie<number>(CookieKey.MiniCurrentDay)) {
        setupDailyChallenge();
      }
    }, 5000);
  }, []);

  const setupDailyChallenge = () => {
    storeCookie(CookieKey.MiniGuessHistory, []);
    const day = getDay();
    setDay(day);
    storeCookie<number>(CookieKey.MiniCurrentDay, day);
    setAnswer(getTodaysWord(numLetters));
  };

  const checkDailyWord = () => {
    const todaysWord = getTodaysWord(numLetters);
    if (answer === todaysWord) {
      return;
    }

    const day = getDay();
    const currentlyCompleting = loadCookie<number>(CookieKey.MiniCurrentDay);
    if (day !== currentlyCompleting) {
      setupDailyChallenge();
      return;
    }

    const dailyChallengeHistory = loadCookie<DailyChallengeHistory[]>(
      CookieKey.MiniDailyChallengeHistory,
    );

    const guessHistory = loadCookie<Guess[]>(CookieKey.MiniGuessHistory) || [];

    setGuessHistory(guessHistory || []);
    setAnswer(todaysWord);

    if (
      dailyChallengeHistory &&
      dailyChallengeHistory.length &&
      dailyChallengeHistory[0].day === day
    ) {
      setIsCorrect(true);
      setResumeGame({
        answer: todaysWord,
        guessHistory,
        guess: todaysWord,
      });
    } else {
      setResumeGame({
        answer: todaysWord,
        guessHistory,
      });
    }
  };

  const onCorrectAnswer = (guessHistory: Guess[]) => {
    setGuessHistory(guessHistory);
    setIsCorrect(true);
    sendEvent(`completed_game`, { noGuesses: guessHistory.length, woodleNumber });
    const dailyChallengeHistory = loadCookie<DailyChallengeHistory[]>(
      CookieKey.MiniDailyChallengeHistory,
    );

    const newHistory = [{ day, noGuesses: guessHistory.length }, ...(dailyChallengeHistory || [])];
    storeCookie(CookieKey.MiniDailyChallengeHistory, newHistory);
    storeCookie(CookieKey.MiniGuessHistory, guessHistory);
  };

  const onIncorrectGuess = (guessHistory: Guess[]) => {
    storeCookie(CookieKey.MiniGuessHistory, guessHistory);
  };

  return (
    <>
      <Game
        answer={answer}
        numLetters={numLetters}
        resumeGame={resumeGame}
        header={
          <HeaderContainer>
            {`Mini #${woodleNumber}`}
            {isCorrect && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PlayAgainButton
                  onClick={() => {
                    navigate('/unlimited/mini');
                  }}
                >
                  Play Unlimited
                </PlayAgainButton>
                <div style={{ marginRight: '1rem' }} />

                <PlayAgainButton
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Play Daily Woodle
                </PlayAgainButton>
              </div>
            )}
          </HeaderContainer>
        }
        onCorrectAnswer={onCorrectAnswer}
        onIncorrectGuess={onIncorrectGuess}
        shareDialog={<ShareModal woodleNumber={woodleNumber} guessHistory={guessHistory} />}
      />
      <CornerPrompt
        hidden={!isCorrect && nextWoodleDate().diff(moment(), 'minutes') > 61}
        woodleNumber={woodleNumber}
      />
    </>
  );
};

export default MiniDailyGame;
