import styled from 'styled-components';

export const ModalFade = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  min-height: 100%;
  min-width: 100%;
  z-index: 99;
  background-color: #00000099;
`;

export const CloseButton = styled.button`
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #00000000;
  border: none;
  font-size: 15px;
  cursor: pointer;
`;
